import { APP_TOKEN_KEY } from '$app/constants'
import { redirect, ROUTE_NAMES } from '$app/router/config'
import { getProfile, updateAxiosHeaderToken } from '$app/services/api'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '$app/utils'
import { useRequest } from 'ahooks'
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { AuthContext } from './context'
import Loading from '$components/Loading'
import { useAxiosInterceptors } from '$hooks/actions'
import { useIsMounted } from '@genie-fintech/ui/hooks'

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState(getLocalStorage<string>(APP_TOKEN_KEY))

  const isMounted = useIsMounted()

  const {
    data: profileData,
    run: fetchProfile,
    loading: fetchingProfile
  } = useRequest(getProfile, {
    manual: true
  })

  const { data: profile } = profileData ?? {}

  const updateToken = useCallback((value?: string) => {
    setToken(value)

    if (value) {
      setLocalStorage(APP_TOKEN_KEY, value)
      return
    }

    removeLocalStorage(APP_TOKEN_KEY)
  }, [])

  useEffect(() => {
    updateAxiosHeaderToken(token)

    if (!token) {
      redirect(ROUTE_NAMES.LOGIN, { replace: true })
      return
    }

    fetchProfile()
  }, [token, fetchProfile])

  useAxiosInterceptors(() => updateToken(undefined))

  if (fetchingProfile || !isMounted) return <Loading />

  return (
    <AuthContext.Provider value={{ user: profile, token, updateToken }}>
      {children}
    </AuthContext.Provider>
  )
}
