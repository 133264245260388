import { URLPattern } from 'urlpattern-polyfill'
import { CreateHistory, utility } from 'rutter'

export const { buildURL } = utility

export enum ROUTE_NAMES {
  LOGIN = 'login',
  INDEX = 'index',
  APPS = 'apps',
  APP_HOME = 'app-home',
  APP_BASIC_SETTING = 'app-basic-setting',
  APP_ADVANCED_SETTING = 'app_advance_setting',
  APP_LAUNCHPAD_SETTING = 'app_launchpad_setting',
  APP_TOKEN_SETTING = 'app_token_setting',
  APP_GROUPS = 'app_groups',
  APP_GROUP_CREATE = 'app_group_create',
  APP_GROUP_EDIT = 'app_group_edit',
  APP_ROLES = 'app_roles',
  APP_ROLES_CSV_IMPORT = 'app_roles_csv_import',
  APP_ROLE_CREATE = 'app_role_create',
  APP_ROLE_EDIT = 'app_role_edit',
  APP_USERS = 'app_users',
  APP_USER_CREATE = 'app_user_create',
  APP_USER_EDIT = 'app_user_edit',
  APP_PERMISSIONS = 'app_permissions',
  APP_PERMISSION_CREATE = 'app_permission_create',
  APP_PERMISSION_EDIT = 'app_permission_edit'
}

export const {
  summaryState,
  routeState,
  watchRouteState,
  watchSummaryState,
  on,
  onOneOf,
  redirect,
  getDetail,
  onRouteMatch
} = new CreateHistory({
  URLPattern,
  routes: {
    [ROUTE_NAMES.INDEX]: {
      pathname: '/',
      meta: {
        title: ''
      }
    },
    [ROUTE_NAMES.APPS]: {
      pathname: '/apps',
      meta: {
        title: 'Apps'
      }
    },
    [ROUTE_NAMES.APP_HOME]: {
      pathname: '/apps/:appId/home',
      meta: {
        title: 'Home'
      }
    },
    [ROUTE_NAMES.APP_BASIC_SETTING]: {
      pathname: '/apps/:appId/basic-setting',
      meta: {
        title: 'Basic Setting'
      }
    },
    [ROUTE_NAMES.APP_ADVANCED_SETTING]: {
      pathname: '/apps/:appId/advance-setting',
      meta: {
        title: 'Advance Setting'
      }
    },
    [ROUTE_NAMES.APP_LAUNCHPAD_SETTING]: {
      pathname: '/apps/:appId/launchpad-setting',
      meta: {
        title: 'Launchpad Setting'
      }
    },
    [ROUTE_NAMES.APP_TOKEN_SETTING]: {
      pathname: '/apps/:appId/token-setting',
      meta: {
        title: 'Token Setting'
      }
    },
    [ROUTE_NAMES.APP_GROUPS]: {
      pathname: '/apps/:appId/groups',
      meta: {
        title: 'Groups'
      }
    },
    [ROUTE_NAMES.APP_GROUP_CREATE]: {
      pathname: '/apps/:appId/groups/create',
      meta: {
        title: 'Create Group'
      }
    },
    [ROUTE_NAMES.APP_GROUP_EDIT]: {
      pathname: '/apps/:appId/groups/:groupId',
      meta: {
        title: 'Edit Group'
      }
    },
    [ROUTE_NAMES.APP_USERS]: {
      pathname: '/apps/:appId/users',
      meta: {
        title: 'Users'
      }
    },
    [ROUTE_NAMES.APP_USER_CREATE]: {
      pathname: '/apps/:appId/users/create',
      meta: {
        title: 'Create User'
      }
    },
    [ROUTE_NAMES.APP_USER_EDIT]: {
      pathname: '/apps/:appId/users/:userId',
      meta: {
        title: 'Edit User'
      }
    },
    [ROUTE_NAMES.APP_ROLES]: {
      pathname: '/apps/:appId/roles',
      meta: {
        title: 'Roles'
      }
    },
    [ROUTE_NAMES.APP_ROLES_CSV_IMPORT]: {
      pathname: '/apps/:appId/roles/import',
      meta: {
        title: 'Import Roles'
      }
    },
    [ROUTE_NAMES.APP_ROLE_CREATE]: {
      pathname: '/apps/:appId/roles/create',
      meta: {
        title: 'Create Role'
      }
    },
    [ROUTE_NAMES.APP_ROLE_EDIT]: {
      pathname: '/apps/:appId/roles/:roleId',
      meta: {
        title: 'Edit Role'
      }
    },
    [ROUTE_NAMES.APP_PERMISSIONS]: {
      pathname: '/apps/:appId/permissions',
      meta: {
        title: 'Permissions'
      }
    },
    [ROUTE_NAMES.APP_PERMISSION_CREATE]: {
      pathname: '/apps/:appId/permissions/create',
      meta: {
        title: 'Create Permission'
      }
    },
    [ROUTE_NAMES.APP_PERMISSION_EDIT]: {
      pathname: '/apps/:appId/permissions/:permissionId',
      meta: {
        title: 'Edit Permission'
      }
    },
    [ROUTE_NAMES.LOGIN]: {
      pathname: '/login',
      meta: {
        title: 'Login'
      }
    }
  }
})

// Scroll to top on page navigate
watchRouteState(({ hash }) => {
  if (hash) return

  setTimeout(() => {
    self.scrollTo({ top: 0 })
  }, 300)
})

type RedirectParams = Parameters<typeof redirect>

export type RouteNames = RedirectParams[0]
export type RedirectOptions = RedirectParams[1]
