import { cn } from '$app/utils'
import {
  AdvanceSettingIcon,
  BasicSettingIcon,
  GroupIcon,
  HomeIcon,
  LaunchPadIcon,
  PermissionIcon,
  RoleIcon,
  TokenIcon,
  UserIcon
} from '$assets/svg'
import AppSwitcher from '$components/AppSwitcher'
import PublishUnpublishBadge from '$components/PublishUnpublishBadge'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppDetailStore } from '$hooks/stores'
import { onOneOf, ROUTE_NAMES, RouteNames } from '$router/config'
import Link from '$router/Link'
import { ReactNode } from 'react'

const SIDE_MENUS: {
  name: string
  path: RouteNames
  Icon: ReactNode
  subPath?: RouteNames[]
}[] = [
  {
    name: 'Home',
    path: ROUTE_NAMES.APP_HOME,
    Icon: <HomeIcon />
  },
  {
    name: 'Basic Setting',
    path: ROUTE_NAMES.APP_BASIC_SETTING,
    Icon: <BasicSettingIcon />
  },
  {
    name: 'Advanced Setting',
    path: ROUTE_NAMES.APP_ADVANCED_SETTING,
    Icon: <AdvanceSettingIcon />
  },
  {
    name: 'Launchpad',
    path: ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
    Icon: <LaunchPadIcon />
  },
  {
    name: 'Token',
    path: ROUTE_NAMES.APP_TOKEN_SETTING,
    Icon: <TokenIcon />
  },
  {
    name: 'Group',
    path: ROUTE_NAMES.APP_GROUPS,
    Icon: <GroupIcon />,
    subPath: [ROUTE_NAMES.APP_GROUP_CREATE, ROUTE_NAMES.APP_GROUP_EDIT]
  },
  {
    name: "App's Users",
    path: ROUTE_NAMES.APP_USERS,
    Icon: <UserIcon />,
    subPath: [ROUTE_NAMES.APP_USER_CREATE, ROUTE_NAMES.APP_USER_EDIT]
  },
  {
    name: "App's Roles",
    path: ROUTE_NAMES.APP_ROLES,
    Icon: <RoleIcon />,
    subPath: [ROUTE_NAMES.APP_ROLE_CREATE, ROUTE_NAMES.APP_ROLE_EDIT]
  },
  {
    name: "App's Permissions",
    path: ROUTE_NAMES.APP_PERMISSIONS,
    Icon: <PermissionIcon />,
    subPath: [
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_EDIT
    ]
  }
]

const Sidebar = () => {
  const { route } = useRouteSummary()

  const is_published =
    useAppDetailStore(state => state.appDetail?.is_published) ?? false

  return (
    <aside className="flex fixed flex-col py-2 w-[236px] gap-y-4 bg-[--colors-area-high] border border-[--colors-neutral-10] rounded-lg shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]">
      <article className="px-2">
        <AppSwitcher />
      </article>

      <article className="flex flex-1 flex-col gap-y-2">
        {SIDE_MENUS.map((menu, key) => {
          const active = onOneOf(
            menu.subPath ? [menu.path, ...menu.subPath] : [menu.path]
          )
          return (
            <article className="relative px-2" key={key}>
              <Link
                to={menu.path}
                options={{ ...route }}
                data-active={active}
                className={cn(
                  'flex gap-x-2 p-3 items-center text-sm text-[--colors-neutral-60] rounded',
                  'data-[active=true]:bg-[--colors-area-low] data-[active=true]:font-semibold data-[active=true]:text-[--colors-text-light]',
                  'data-[active=false]:hover:bg-[--colors-area-low] transition-all duration-500'
                )}
              >
                {menu.Icon}

                <p>{menu.name}</p>

                {active && (
                  <span className="w-[3px] h-full bg-[--colors-primary-default] absolute right-0 rounded-l-lg" />
                )}
              </Link>
            </article>
          )
        })}

        <article className="p-3 border-t border-[--colors-neutral-10]">
          <PublishUnpublishBadge is_published={is_published} />
        </article>
      </article>
    </aside>
  )
}

export default Sidebar
