import { getCountries } from '$services/api'
import { useRequest } from 'ahooks'

export const useCountryService = () => {
  const {
    run: fetchCountries,
    runAsync: fetchCountriesAsync,
    loading: fetchingCountries,
    data
  } = useRequest(getCountries, {
    manual: true
  })

  const { data: countries = [] } = { ...data }

  const countryOptions = countries.map(v => ({
    label: v.name,
    value: `${v.id}`
  }))

  const phoneOptions = countries.map(v => ({
    label: v.phone_code,
    value: v.phone_code
  }))

  return {
    fetchCountries,
    fetchCountriesAsync,
    fetchingCountries,
    countries,

    countryOptions,
    phoneOptions
  }
}
