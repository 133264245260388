import Loading from '$components/Loading'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService, useCountryService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppGroupPayload } from '$services/api'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { schema, TFormValues } from './constants'

const descriptionText = `Setup a mobile, web or IoT application to use CARROsso for Authentication.`

interface IAppGroupFormProps {
  defaultValues: TFormValues
  appName: string
}

const AppGroupForm = ({ defaultValues, appName }: IAppGroupFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const {
    fetchCountriesAsync,
    fetchingCountries,
    countryOptions,
    phoneOptions
  } = useCountryService()

  const { saveGroupAsync, savingGroup, updateGroupAsync, updatingGroup } =
    useAppGroupService()

  useMount(() => {
    fetchCountriesAsync().then(({ data }) => {
      if (groupId) return
      reset({ ...defaultValues, country: `${data[0]?.id ?? ''}` })
    })
  })

  const handleOnRedirect = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUPS, { params })
  }, [params])

  const title = (() => {
    if (groupId) return `Edit ${defaultValues.name}`
    return `Add New Group for ${appName}`
  })()

  const handleOnCancel = useCallback(() => {
    reset({
      ...defaultValues,
      country: countryOptions.at(0)?.value ?? ''
    })
  }, [reset, defaultValues, countryOptions])

  const onSubmit = useCallback(
    (values: TFormValues) => {
      if (!appId) return

      const { country, phone_code, ...rest } = values

      const payload: TAppGroupPayload = {
        country_id: +country,
        phone_code: phone_code.value,
        ...rest
      }

      if (groupId) {
        updateGroupAsync(appId, groupId, payload).then(handleOnRedirect)
        return
      }

      saveGroupAsync(appId, payload).then(handleOnRedirect)
    },
    [appId, groupId, updateGroupAsync, saveGroupAsync, handleOnRedirect]
  )

  const isProcessing = savingGroup || updatingGroup

  if (fetchingCountries || !countryOptions.length) return <Loading />

  return (
    <form
      className="flex flex-col gap-y-4 flex-1"
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="flex items-center gap-2 justify-between">
        <p className="text-xl font-semibold text-[--colors-text-light]">
          {title}
        </p>

        <article className="flex items-center gap-x-2">
          {groupId && (
            <SaveWithShortCutButton
              disabled={!isDirty || isProcessing}
              loading={isProcessing}
            />
          )}

          {!groupId && (
            <>
              <Button
                disabled={isProcessing}
                styleVariants={{ type: 'outlined', kind: 'neutral' }}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDirty || isProcessing}
                type="submit"
                className="!px-4"
              >
                {isProcessing && <Spinner />}
                Add
              </Button>
            </>
          )}
        </article>
      </header>

      <main className="flex-1 flex flex-col gap-y-4">
        <article className="flex flex-col gap-y-2 bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
          <p className="font-semibold text-[--colors-text-neutral]">
            COUNTRY/REGION
          </p>
          <p className="text-[--colors-neutral-50] text-xs">
            {descriptionText}
          </p>

          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              return (
                <Tabs.Root
                  value={field.value}
                  onValueChange={field.onChange}
                  format={{ type: 'segmented' }}
                >
                  <Tabs.List styleVariants={{ hAlign: 'left' }}>
                    {countryOptions.map((v, k) => (
                      <Tabs.Trigger
                        key={k}
                        value={`${v.value}`}
                        disabled={!!groupId}
                      >
                        {v.label}
                      </Tabs.Trigger>
                    ))}
                  </Tabs.List>
                </Tabs.Root>
              )
            }}
          />
        </article>

        <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
          <article className="flex flex-col gap-y-1">
            <p className="font-semibold text-[--colors-text-neutral]">
              GROUP INFO
            </p>
            <p className="text-[--colors-neutral-50] text-xs">
              {descriptionText}
            </p>
          </article>

          <article className="flex flex-col gap-y-4 px-5 max-w-[500px]">
            <BaseHookFieldText
              control={control}
              name="name"
              label="Group Name"
              required
            />

            <article className="relative">
              <BaseHookFieldText
                control={control}
                name="phone_no"
                label="Phone Number"
                inputProps={{ className: 'pl-[100px]' }}
              />

              <article className="absolute bottom-0 left-0 w-[100px]">
                <Controller
                  name="phone_code"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        options={phoneOptions}
                        type="sub"
                      />
                    )
                  }}
                />
              </article>
            </article>

            <Textarea name="address" control={control} label="Address" />

            <Textarea
              name="description"
              control={control}
              label="Description"
            />
          </article>
        </article>
      </main>
    </form>
  )
}

export default AppGroupForm
