import { cn, formatFileSize, TFileUploadData, uploadFile } from '$app/utils'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useImportService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { errorMessageResolver, errorStatusResolver } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import {
  AlertTriangle,
  Check,
  FolderCheck,
  FolderOpen,
  Paperclip
} from 'lucide-react'
import { DragEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'sonner'

const ALLOWED_FILE_TYPE = [{ name: 'CSV', type: 'text/csv' }]

type TInvalidRecord = {
  row: number
  messages: string[]
}

const columns: {
  key: keyof TInvalidRecord
  value: string
}[] = [
  { key: 'row', value: 'ROW NUMBER' },
  { key: 'messages', value: 'ERROR' }
]

const CSVImport = () => {
  const [dragActive, setDragActive] = useState<boolean>(false)

  const [uploading, setUploading] = useState(false)

  const [invalidRecords, setInvalidRecords] = useState<TInvalidRecord[]>([])

  const [uploadedFileData, setUploadedFileData] =
    useState<TFileUploadData | null>(null)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { importDataAsync, importingData } = useImportService()

  useEffect(() => {
    if (!uploadedFileData || !appId) return

    importDataAsync({ appId, type: 'role', file: uploadedFileData.key }).catch(
      err => {
        const status = errorStatusResolver(err)

        if (status === 422) {
          const records = err?.response?.data?.errors?.data ?? []
          setInvalidRecords(records as unknown as TInvalidRecord[])
          return
        }

        setUploadedFileData(null)
      }
    )
  }, [uploadedFileData, appId, importDataAsync])

  const handleOnSelectFile = useCallback((fileList: FileList | null) => {
    setInvalidRecords([])

    setUploadedFileData(null)

    const [file] = fileList ?? []

    if (!file) return null

    if (!ALLOWED_FILE_TYPE.map(v => v.type).includes(file.type)) {
      toast.error('Oops! file type is not supported!')
      return null
    }

    setUploading(true)

    uploadFile(file)
      .then((value: TFileUploadData) => {
        setUploadedFileData(value)
      })
      .catch(err => {
        toast.error(errorMessageResolver(err))
      })
      .finally(() => {
        setUploading(false)
      })
  }, [])

  const handleOnDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
      return
    }

    if (event.type === 'dragleave') {
      setDragActive(false)
      return
    }
  }, [])

  const handleOnDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      setDragActive(false)

      handleOnSelectFile(event.dataTransfer.files)
    },
    [handleOnSelectFile]
  )

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log('hello')
      handleOnSelectFile(event.currentTarget.files)
    },
    [handleOnSelectFile]
  )

  const handleOnBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLES, { params })
  }, [params])

  const isProcessing = uploading || importingData

  return (
    <article className="flex-1 flex flex-col gap-4 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] p-5 rounded-lg">
      <p className="font-semibold">Role Import .CSV file here!</p>

      <article className="flex justify-between items-end">
        <article className="flex flex-col text-[--colors-neutral-50] text-xs">
          <p>
            You can explore the data table format by downloading this template.
          </p>
          <p>If the unique data be same, we will replace those data.</p>
        </article>

        <Button styleVariants={{ type: 'text', size: 'small' }} disabled>
          Download example .CSV template
        </Button>
      </article>

      <article
        onDragEnter={handleOnDrag}
        onDragOver={handleOnDrag}
        onDragLeave={handleOnDrag}
        onDrop={handleOnDrop}
        className={cn(
          'flex flex-col items-center gap-y-4 border border-[--colors-neutral-10] py-10 rounded-lg bg-[--colors-alphaArea-disabled] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] relative',
          dragActive
            ? 'border-dashed border-[--colors-neutral-default]'
            : 'border-solid border-[--colors-neutral-20]'
        )}
      >
        {(uploadedFileData || isProcessing) && (
          <article className="flex flex-col gap-2 flex-1">
            <article className="flex flex-1 rounded-xl w-[350px] gap-2 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] p-2 items-center">
              <article className="inline-flex bg-[--colors-alphaPrimary-0] rounded-lg p-3 text-[--colors-primary-default]">
                {isProcessing && <Spinner />}

                {!isProcessing && <FolderCheck />}
              </article>

              <p className="flex-1 text-sm font-medium">
                {isProcessing
                  ? 'Documents uploading...'
                  : 'Documents uploaded successfully.'}
              </p>
            </article>

            {uploadedFileData && (
              <article className="flex items-center gap-2 text-[--colors-neutral-60] text-sm">
                <Paperclip size={18} />

                <p className="font-medium flex-1">
                  {(() => {
                    const {
                      file_name,
                      file: { type }
                    } = uploadedFileData

                    const fileType = ALLOWED_FILE_TYPE.find(
                      d => d.type === type
                    )?.name

                    return `${file_name}.${fileType}`
                  })()}
                </p>

                <p>{formatFileSize(uploadedFileData.file.size)}</p>
              </article>
            )}
          </article>
        )}

        {!uploadedFileData && !isProcessing && (
          <article className="flex-1 flex flex-col gap-y-4">
            <article className="flex justify-center">
              <article className="inline-flex text-[--colors-primary-default] bg-[--colors-alphaPrimary-0] rounded-xl p-5">
                <FolderOpen />
              </article>
            </article>

            <p className="font-medium text-sm text-[--colors-text-disabled]">
              Drop your .CSV file onto this area to upload
            </p>

            <article className="flex gap-4 items-center">
              <span className="flex-1 border-t border-[--colors-neutral-10]" />
              <span className="text-sm font-medium text-[--colors-neutral-50]">
                Or
              </span>
              <span className="flex-1 border-t border-[--colors-neutral-10]" />
            </article>

            <article className="flex justify-center">
              <label
                htmlFor="hiddenFileInput"
                className="flex items-center gap-x-2 text-sm font-medium text-[--colors-primary-default] cursor-pointer"
              >
                <span>Click to Upload</span>

                <input
                  type="file"
                  value=""
                  id="hiddenFileInput"
                  onChange={handleFileChange}
                  className="hidden"
                  accept={ALLOWED_FILE_TYPE.map(v => v.type).join(',')}
                />
              </label>
            </article>
          </article>
        )}
      </article>

      <p className="inline-flex items-center gap-x-1 text-xs">
        <span className="text-[--colors-neutral-50]">Supports doc type is</span>
        <span className="font-semibold">.CSV file only</span>
      </p>

      {!!uploadedFileData && !isProcessing && (
        <article className="flex flex-col flex-1 max-h-[600px] overflow-y-auto rounded-xl gap-2 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] p-2">
          {(() => {
            const Icon = invalidRecords.length ? AlertTriangle : Check
            const className = invalidRecords.length
              ? 'bg-[--colors-alphaWarning-0] text-[--colors-warning-80]'
              : 'bg-[--colors-alphaSuccess-0] text-[--colors-success-80]'
            const title = invalidRecords.length
              ? `${invalidRecords.length} rows could not be imported.`
              : 'All data have been imported.'
            const desc = invalidRecords.length
              ? "We couldn't process these rows due to missing or invalid data. Please check for missing  or incorrect data and import again."
              : 'We will process with the imported data to contribute in the role table.'

            return (
              <article className="flex gap-2 items-center sticky top-0 z-10">
                <article
                  className={cn('inline-flex p-2 rounded-lg', className)}
                >
                  <Icon />
                </article>
                <article className="flex flex-col">
                  <p className="text-sm font-medium">{title}</p>
                  <p className="text-xs text-[--colors-neutral-50]">{desc}</p>
                </article>
              </article>
            )
          })()}

          {!!invalidRecords.length && (
            <article className={customTableContainer}>
              <main className={tableContainerInner}>
                <table className={table({ separator: 'line' })}>
                  <thead className={customTableHead}>
                    <tr className={tableRow}>
                      {columns.map((col, key) => (
                        <th
                          key={key}
                          className={cn(customTableCell, 'text-center')}
                        >
                          {col.value}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={tableBody}>
                    {invalidRecords.map((record, rowKey) => (
                      <tr key={rowKey} className={tableRow}>
                        {columns.map((col, colKey) => (
                          <td
                            key={colKey}
                            className={cn(customTableCell, 'text-center')}
                          >
                            {(() => {
                              if (col.key === 'messages') {
                                return (
                                  <article className="inline-flex flex-col">
                                    {record.messages.map(
                                      (message, messageKey) => (
                                        <article
                                          key={messageKey}
                                          className="flex items-center gap-1"
                                        >
                                          <AlertTriangle
                                            className="text-[--colors-warning-70]"
                                            size={16}
                                          />
                                          <p className="font-medium text-sm text-[--colors-text-light]">
                                            {message}
                                          </p>
                                        </article>
                                      )
                                    )}
                                  </article>
                                )
                              }
                              return record[col.key]
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </main>
            </article>
          )}
        </article>
      )}

      <article className="flex items-center gap-2">
        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined' }}
          onClick={handleOnBack}
        >
          Back to Listing
        </Button>

        <article className="flex-1" />

        {uploadedFileData && (
          <article className="flex justify-center">
            <label
              htmlFor="hiddenFileInput"
              className="flex items-center gap-x-2 text-sm font-medium text-[--colors-primary-default] cursor-pointer"
            >
              <span>Click to add more file!</span>

              <input
                type="file"
                value=""
                id="hiddenFileInput"
                onChange={handleFileChange}
                className="hidden"
                accept={ALLOWED_FILE_TYPE.map(v => v.type).join(',')}
              />
            </label>
          </article>
        )}
      </article>
    </article>
  )
}

export default CSVImport
