import { RoleIcon } from '$assets/svg'
import ActiveInactiveStatusBadge from '$components/ActiveInactiveStatusBadge'
import Breadcrumb from '$components/Breadcrumb'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppUserService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { TAppUserListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Avatar, Button, Pager } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useCallback, useEffect } from 'react'

const columns: {
  key: keyof Omit<TAppUserListResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'USER NAME' },
  { key: 'role_count', value: 'ROLE' },
  { key: 'group_count', value: 'GROUP' },
  { key: 'email', value: 'EMAIL' },
  { key: 'status', value: 'STATUS' },
  { key: 'action', value: '' }
]

const AppUserList = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const {
    fetchAppUserList,
    fetchingAppUserList,
    users: { list, meta }
  } = useAppUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppUserList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppUserList, page, perPage, debouncedSearchValue])

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_CREATE, { params })
  }, [params])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[{ name: `${appName} Users` }]}
      />

      <article className="flex-1 flex flex-col gap-y-8 relative bg-[--colors-area-high] rounded-lg overflow-hidden">
        <header className="flex items-center justify-between h-[56px] px-3 bg-[--colors-area-high] border-b border-[--colors-neutral-10]">
          <article className="flex items-center gap-x-2 text-[--colors-text-light]">
            <RoleIcon />
            <p className="text-xl font-semibold">Users</p>
          </article>
          <Button onClick={handleOnClickAddNew}>
            <Icon namespace="Add" />
            Add New
          </Button>
        </header>

        <main className="xl:px-20 lg:px-10 px-5 flex flex-col gap-y-4 flex-1">
          <article className="flex flex-col gap-y-1">
            <p className="font-semibold">{appName}'s USERS</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Setup a mobile, web or IoT application to use CARROsso for
              Authentication.
            </p>
          </article>

          <article className="flex gap-x-2 items-center justify-between">
            <article>
              <BaseText
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: searchValue ? (
                    <button onClick={() => updateSearchValue('')}>
                      <Icon namespace="Cross" width={18} />
                    </button>
                  ) : undefined
                }}
                inputProps={{
                  type: 'text',
                  value: searchValue,
                  onChange: e => updateSearchValue(e.currentTarget.value),
                  placeholder: 'Search here...'
                }}
              />
            </article>

            {!!meta?.total && (
              <p className="text-[--colors-text-disabled] text-sm font-medium">
                {meta.total} USER{meta.total > 1 && 'S'}
              </p>
            )}
          </article>

          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      <Avatar size={30} />
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>

                {!fetchingAppUserList && (
                  <tbody className={tableBody}>
                    {list.map((data, rowKey) => (
                      <tr key={rowKey} className={tableRow}>
                        <td className={customTableCell}>
                          <Avatar
                            status={
                              data.status === 'active' ? 'online' : undefined
                            }
                            size={30}
                          />
                        </td>
                        {columns.map((col, colKey) => (
                          <td key={colKey} className={customTableCell}>
                            {(() => {
                              if (col.key === 'action') {
                                return (
                                  <button
                                    type="button"
                                    className="flex items-center text-[--colors-text-light] hover:text-[--colors-primary-default] duration-200"
                                  >
                                    <Icon
                                      namespace="MoreHorizontal"
                                      width={18}
                                    />
                                  </button>
                                )
                              }

                              if (col.key === 'name') {
                                return (
                                  <Link
                                    className="cursor-pointer hover:text-[--colors-primary-default] duration-200 font-semibold"
                                    to={ROUTE_NAMES.APP_USER_EDIT}
                                    options={{
                                      params: { ...params, userId: data.id }
                                    }}
                                  >
                                    <p>{data.name}</p>
                                  </Link>
                                )
                              }

                              if (col.key === 'status') {
                                return (
                                  <ActiveInactiveStatusBadge
                                    status={data.status}
                                  />
                                )
                              }

                              return data[col.key] ?? 'N/A'
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {!list.length && (
                      <tr className={tableRow}>
                        <td
                          colSpan={columns.length}
                          style={{ textAlign: 'center' }}
                          className={customTableCell}
                        >
                          NO DATA
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </main>
          </article>

          {fetchingAppUserList && <Loading />}
        </main>

        {!!list.length && (
          <footer className="sticky bottom-0 bg-[--colors-area-high] p-4">
            <Pager align="right" {...pagerProps} />
          </footer>
        )}
      </article>
    </>
  )
}

export default AppUserList
