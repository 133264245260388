import { FC, PropsWithChildren, useEffect } from 'react'
import Sidebar from './Sidebar'
import { useAppBasicSettingService, useAppScopeService } from '$hooks/services'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useMount } from 'ahooks'
import { useIsMounted } from '@genie-fintech/ui/hooks'

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const {
    route: {
      params: { appId }
    }
  } = useRouteSummary()

  const isMounted = useIsMounted()

  const { fetchAppBasicSetting, fetchingAppBasicSetting } =
    useAppBasicSettingService()

  const { fetchAppScope, fetchingAppScope } = useAppScopeService()

  useEffect(() => {
    if (!appId) return
    fetchAppBasicSetting({ application_id: appId })
  }, [appId, fetchAppBasicSetting])

  useMount(() => {
    fetchAppScope()
  })

  const isFetching = fetchingAppBasicSetting || fetchingAppScope || !isMounted

  if (isFetching) return <Loading />

  return (
    <section className="flex flex-col gap-y-2 flex-1">
      <article
        id="breadcrumb-bar"
        className="flex items-center ml-[236px] px-4 h-[40px]"
      >
        {/* Breadcrumb bar  */}
      </article>

      <article className="flex-1 flex">
        <Sidebar />

        <article className="flex-1 flex flex-col ml-[236px] px-4">
          {children}
        </article>
      </article>
    </section>
  )
}

export default AppLayout
