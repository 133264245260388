import { FC } from 'react'
import { on, onOneOf, ROUTE_NAMES } from './config'
import { useRoute } from '$app/contexts/RouteContext/hooks'
import Login from '$pages/Login'
import PageLayout from '$layouts/PageLayout'
import Navigate from './Navigate'
import { AuthProvider } from '$contexts/AuthContext/AuthProvider'
import AppList from '$pages/AppList'
import AppLayout from '$layouts/AppLayout'
import AppHome from '$pages/AppHome'
import AppBasicSetting from '$pages/AppBasicSetting'
import AppAdvancedSetting from '$pages/AppAdvancedSetting'
import AppLaunchPadSetting from '$pages/AppLaunchPadSetting'
import AppTokenSetting from '$pages/AppTokenSetting'
import AppGroupList from '$pages/AppGroup/List'
import AppGroupCreate from '$pages/AppGroup/Create'
import AppGroupEdit from '$pages/AppGroup/Edit'
import AppRoleList from '$pages/AppRole/List'
import AppRoleCreate from '$pages/AppRole/Create'
import AppRoleEdit from '$pages/AppRole/Edit'
import AppUserList from '$pages/AppUser/List'
import AppPermissionList from '$pages/AppPermission/List'
import AppPermissionCreate from '$pages/AppPermission/Create'
import AppPermissionEdit from '$pages/AppPermission/Edit'
import AppUserCreate from '$pages/AppUser/Create'
import AppUserEdit from '$pages/AppUser/Edit'
import AppRoleCSVImport from '$pages/AppRoleCSVImport'

export const Router: FC = () => {
  const { is404 } = useRoute()

  if (is404) return '404'

  return (
    <AuthProvider>
      {on(ROUTE_NAMES.LOGIN) && <Login />}

      {on(ROUTE_NAMES.INDEX) && <Navigate to={ROUTE_NAMES.APPS} replace />}

      {!onOneOf([ROUTE_NAMES.LOGIN, ROUTE_NAMES.INDEX]) && (
        <PageLayout>
          {on(ROUTE_NAMES.APPS) && <AppList />}

          {on(ROUTE_NAMES.APP_ROLES_CSV_IMPORT) && <AppRoleCSVImport />}

          {!onOneOf([ROUTE_NAMES.APPS, ROUTE_NAMES.APP_ROLES_CSV_IMPORT]) && (
            <AppLayout>
              {on(ROUTE_NAMES.APP_HOME) && <AppHome />}

              {on(ROUTE_NAMES.APP_BASIC_SETTING) && <AppBasicSetting />}

              {on(ROUTE_NAMES.APP_ADVANCED_SETTING) && <AppAdvancedSetting />}

              {on(ROUTE_NAMES.APP_LAUNCHPAD_SETTING) && <AppLaunchPadSetting />}

              {on(ROUTE_NAMES.APP_TOKEN_SETTING) && <AppTokenSetting />}

              {on(ROUTE_NAMES.APP_GROUPS) && <AppGroupList />}

              {on(ROUTE_NAMES.APP_GROUP_CREATE) && <AppGroupCreate />}

              {on(ROUTE_NAMES.APP_GROUP_EDIT) && <AppGroupEdit />}

              {on(ROUTE_NAMES.APP_ROLES) && <AppRoleList />}

              {on(ROUTE_NAMES.APP_ROLE_CREATE) && <AppRoleCreate />}

              {on(ROUTE_NAMES.APP_ROLE_EDIT) && <AppRoleEdit />}

              {on(ROUTE_NAMES.APP_USERS) && <AppUserList />}

              {on(ROUTE_NAMES.APP_USER_CREATE) && <AppUserCreate />}

              {on(ROUTE_NAMES.APP_USER_EDIT) && <AppUserEdit />}

              {on(ROUTE_NAMES.APP_PERMISSIONS) && <AppPermissionList />}

              {on(ROUTE_NAMES.APP_PERMISSION_CREATE) && <AppPermissionCreate />}

              {on(ROUTE_NAMES.APP_PERMISSION_EDIT) && <AppPermissionEdit />}
            </AppLayout>
          )}
        </PageLayout>
      )}

      {/* MORE OTHER ROUTES  */}
    </AuthProvider>
  )
}
