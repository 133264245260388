import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppRoleService, useCountryService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { TAppRoleListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button, Checkbox, Pager, Tabs } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useMount } from 'ahooks'
import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { TFormValues } from '../Form/constants'

const columns: {
  key: keyof Omit<TAppRoleListResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'user_count', value: 'USERS' },
  { key: 'permission_count', value: 'PERMISSIONS' },
  { key: 'group', value: 'GROUP' },
  { key: 'action', value: '' }
]

const AppPermissionRoleList = ({ appName }: { appName: string }) => {
  const [selectedCountryId, setSelectedCountryId] = useState('')

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { control } = useFormContext<TFormValues>()

  const { replace, append } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  const {
    fetchAppRoleList,
    fetchingAppRoleList,
    roles: { list, meta }
  } = useAppRoleService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { fetchCountries, fetchingCountries, countryOptions } =
    useCountryService()

  const { currentPage: page } = pagerProps

  useMount(() => {
    fetchCountries()
  })

  useEffect(() => {
    if (!appId) return
    fetchAppRoleList({
      page,
      per_page: perPage,
      application_id: appId,
      country_id: selectedCountryId ?? undefined,
      q: debouncedSearchValue
    })
  }, [
    appId,
    fetchAppRoleList,
    page,
    perPage,
    selectedCountryId,
    debouncedSearchValue
  ])

  if (fetchingCountries) return <Loading />

  return (
    <article className="flex flex-col gap-y-4 bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
      <article className="flex flex-col gap-y-1">
        <p className="font-semibold">{appName}'s ROLES</p>
        <p className="text-xs text-[--colors-neutral-50]">
          Setup a mobile, web or IoT application to use CARROsso for
          Authentication.
        </p>
      </article>

      <article className="flex gap-x-2 items-center justify-between">
        <article>
          <BaseText
            affix={{
              pre: <Icon namespace="Search" width={16} />,
              post: searchValue ? (
                <button onClick={() => updateSearchValue('')}>
                  <Icon namespace="Cross" width={18} />
                </button>
              ) : undefined
            }}
            inputProps={{
              type: 'text',
              value: searchValue,
              onChange: e => updateSearchValue(e.currentTarget.value),
              placeholder: 'Search here...'
            }}
          />
        </article>

        {!!meta?.total && (
          <p className="text-[--colors-text-disabled] text-sm font-medium">
            {meta.total} ROLE{meta.total > 1 && 'S'}
          </p>
        )}
      </article>

      <article>
        <Tabs.Root
          value={selectedCountryId}
          onValueChange={setSelectedCountryId}
          format={{ type: 'segmented' }}
        >
          <Tabs.List styleVariants={{ hAlign: 'left' }}>
            {[{ label: 'All', value: '' }, ...countryOptions].map((v, k) => (
              <Tabs.Trigger key={k} value={`${v.value}`}>
                {v.label}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        </Tabs.Root>
      </article>

      <article className={customTableContainer}>
        <main className={tableContainerInner}>
          <table className={table({ separator: 'line' })}>
            <thead className={customTableHead}>
              <tr className={tableRow}>
                <th style={{ width: 0 }} className={customTableCell}>
                  <Checkbox
                    boxProps={{
                      checked: list.every(d => selectedRoles.includes(d.id)),
                      onCheckedChange: checked => {
                        if (checked) {
                          append(list.map(v => v.id))
                          return
                        }

                        replace(
                          selectedRoles.filter(
                            d => !list.map(v => v.id).includes(d)
                          )
                        )
                      }
                    }}
                  />
                </th>
                <th className={customTableCell} style={{ width: 0 }}>
                  <Icon namespace="User" color="text.light" width={18} />
                </th>
                {columns.map((col, key) => (
                  <th key={key} className={customTableCell}>
                    {col.value}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={tableBody}>
              {list.map((data, rowKey) => (
                <tr key={rowKey} className={tableRow}>
                  <td className={customTableCell}>
                    <Checkbox
                      boxProps={{
                        checked: selectedRoles.includes(data.id),
                        onCheckedChange: checked => {
                          if (checked) {
                            append(data.id)
                            return
                          }

                          replace(selectedRoles.filter(d => d !== data.id))
                        }
                      }}
                    />
                  </td>
                  <td className={customTableCell}>
                    <Icon namespace="User" color="text.light" width={18} />
                  </td>
                  {columns.map((col, colKey) => (
                    <td key={colKey} className={customTableCell}>
                      {(() => {
                        if (col.key === 'action') {
                          return (
                            <article>
                              <Link
                                to={ROUTE_NAMES.APP_ROLE_EDIT}
                                options={{
                                  params: { ...params, roleId: data.id }
                                }}
                                className="relative"
                              >
                                <Button
                                  styleVariants={{
                                    type: 'text',
                                    category: 'icon',
                                    kind: 'neutral'
                                  }}
                                >
                                  <span className="-rotate-45">
                                    <Icon namespace="Forward" width={18} />
                                  </span>
                                </Button>

                                <span className="sr-only">Role Detail</span>
                              </Link>
                            </article>
                          )
                        }

                        if (col.key === 'name') {
                          return (
                            <Link
                              className="cursor-pointer hover:text-[--colors-primary-default] duration-200 font-semibold"
                              to={ROUTE_NAMES.APP_ROLE_EDIT}
                              options={{
                                params: { ...params, roleId: data.id }
                              }}
                            >
                              <p>{data.name}</p>
                            </Link>
                          )
                        }

                        if (col.key === 'group') {
                          return data.group.name
                        }

                        return data[col.key] ?? 'N/A'
                      })()}
                    </td>
                  ))}
                </tr>
              ))}

              {!list.length && (
                <tr className={tableRow}>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: 'center' }}
                    className={customTableCell}
                  >
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </main>

        {fetchingAppRoleList && <Loading />}
      </article>

      {!!list.length && (
        <footer className="sticky bottom-0 bg-[--colors-area-high] p-4">
          <Pager align="right" {...pagerProps} />
        </footer>
      )}
    </article>
  )
}

export default AppPermissionRoleList
