import Loading from '$components/Loading'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import {
  useAppGroupService,
  useAppRoleService,
  useCountryService
} from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppRolePayload } from '$services/api'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import AppRolePermissionList from '../PermissionList'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
// import PermissionList from './PermissionList'

const descriptionText = `Setup a mobile, web or IoT application to use CARROsso for Authentication.`

interface IAppRoleFormProps {
  defaultValues: TFormValues
  appName: string
}

const AppRoleForm = ({ defaultValues, appName }: IAppRoleFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, roleId } = params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty }
  } = methods

  const { fetchCountriesAsync, countryOptions, fetchingCountries } =
    useCountryService()

  const { groupOptions, fetchGroupSelectListAsync } = useAppGroupService()

  const { saveRoleAsync, savingRole, updateRoleAsync, updatingRole } =
    useAppRoleService()

  const countryId = useWatch({ name: 'country_id', control })

  useMount(() => {
    fetchCountriesAsync().then(({ data }) => {
      if (roleId) return
      reset({ ...defaultValues, country_id: `${data[0]?.id ?? ''}` })
    })
  })

  useEffect(() => {
    if (!appId) return
    fetchGroupSelectListAsync({
      application_id: appId,
      country_id: countryId ?? undefined
    })
  }, [appId, countryId, fetchGroupSelectListAsync])

  const handleOnRedirect = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLES, { params })
  }, [params])

  const title = (() => {
    if (roleId) return `Edit ${defaultValues.name}`
    return `Add New Role for ${appName}`
  })()

  const handleOnCancel = useCallback(() => {
    reset({
      ...defaultValues,
      country_id: countryOptions.at(0)?.value ?? ''
    })
  }, [reset, defaultValues, countryOptions])

  const onSubmit = useCallback(
    ({ group, name, description, permissions }: TFormValues) => {
      if (!appId) return

      const payload: TAppRolePayload = {
        group_id: +group.value,
        name,
        description,
        permissions
      }

      if (roleId) {
        updateRoleAsync(appId, roleId, payload).then(handleOnRedirect)
        return
      }

      saveRoleAsync(appId, payload).then(handleOnRedirect)
    },
    [appId, roleId, updateRoleAsync, saveRoleAsync, handleOnRedirect]
  )

  const isProcessing = savingRole || updatingRole

  if (fetchingCountries || !countryOptions.length) return <Loading />

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-y-4 flex-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <header className="flex items-center gap-2 justify-between">
          <p className="text-xl font-semibold text-[--colors-text-light]">
            {title}
          </p>

          <article className="flex items-center gap-x-2">
            {roleId && (
              <SaveWithShortCutButton
                disabled={!isDirty || isProcessing}
                loading={isProcessing}
              />
            )}

            {!roleId && (
              <>
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'outlined', kind: 'neutral' }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isDirty || isProcessing}
                  type="submit"
                  className="!px-4"
                >
                  {isProcessing && <Spinner />}
                  Add
                </Button>
              </>
            )}
          </article>
        </header>

        <main className="flex-1 flex flex-col gap-y-4">
          <article className="flex flex-col gap-y-2 bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
            <p className="font-semibold text-[--colors-text-neutral]">
              COUNTRY/REGION
            </p>
            <p className="text-[--colors-neutral-50] text-xs">
              {descriptionText}
            </p>

            {!!countryOptions.length && (
              <Controller
                name="country_id"
                control={control}
                render={({ field }) => {
                  return (
                    <Tabs.Root
                      value={field.value}
                      onValueChange={value => {
                        field.onChange(value)
                        setValue('group', { label: '', value: '' })
                      }}
                      format={{ type: 'segmented' }}
                    >
                      <Tabs.List styleVariants={{ hAlign: 'left' }}>
                        {countryOptions.map((v, k) => (
                          <Tabs.Trigger key={k} value={`${v.value}`}>
                            {v.label}
                          </Tabs.Trigger>
                        ))}
                      </Tabs.List>
                    </Tabs.Root>
                  )
                }}
              />
            )}
          </article>

          <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
            <article className="flex flex-col gap-y-1">
              <p className="font-semibold text-[--colors-text-neutral]">
                ROLE INFO
              </p>
              <p className="text-[--colors-neutral-50] text-xs">
                {descriptionText}
              </p>
            </article>

            <article className="flex flex-col gap-y-4 px-5 max-w-[500px]">
              <BaseHookFieldText
                control={control}
                name="name"
                label="Role Name"
                required
              />

              <Controller
                name="group"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">Group</label>
                      <Select
                        {...field}
                        options={groupOptions}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <Textarea
                name="description"
                control={control}
                label="Description"
                required
              />
            </article>
          </article>

          <AppRolePermissionList appName={appName} />

          {roleId && (
            <DeleteWithConfirmInput type="role" name={defaultValues.name} />
          )}
        </main>
      </form>
    </FormProvider>
  )
}

export default AppRoleForm
